import React, { useContext } from "react"
import cx from "classnames"

import { LayoutContext } from "@layouts/BaseLayout"
import { Container } from "@ui/Container"
import { Row } from "@ui/Row"
import { LinkToForm } from "@components/common/LinkToForm"
import TrianglesFirst from "@icons/contacts/ContactsDesign1.svg"
import TrianglesSecond from "@icons/contacts/ContactsDesign2.svg"

import * as s from "./Map.module.sass"
import { StaticImage } from "gatsby-plugin-image"

type MapProps = {
  className?: string
}

export const Map: React.FC<MapProps> = ({ className }) => {
  const { langKey } = useContext(LayoutContext)

  return (
    <section className={cx(s.root, className)}>
      <div className={s.image}>
        <StaticImage
          src="../../../images/Map.jpg"
          alt={langKey === "ua" ? "Контаки" : "Контакты"}
          layout="fullWidth"
          style={{ height: "100%" }}
        />
      </div>
      <TrianglesFirst className={s.trianglesFirst} />
      <TrianglesSecond className={s.trianglesSecond} />
      <Container>
        <Row className={s.row}>
          <LinkToForm className={s.link} />
          <h1 className={s.header}>
            {langKey === "ua" ? "Контаки" : "Контакты"}
          </h1>
        </Row>
      </Container>
    </section>
  )
}

// extracted by mini-css-extract-plugin
export const root = "Information-module--root--3QAK1";
export const trianglesFirst = "Information-module--trianglesFirst--1oLTC";
export const trianglesSecond = "Information-module--trianglesSecond--2G7n9";
export const trianglesThird = "Information-module--trianglesThird--3Vdly";
export const contentWrapper = "Information-module--contentWrapper--2nHqa";
export const row = "Information-module--row--2Ej-N";
export const content = "Information-module--content--1jdGJ";
export const phones = "Information-module--phones--Snh2A";
export const email = "Information-module--email--3Hohw";
export const image = "Information-module--image--1-UFU";
export const container = "Information-module--container--28YrV";
export const logoLink = "Information-module--logoLink--T7OiX";
export const logo = "Information-module--logo--32mHs";
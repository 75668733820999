import React from "react"
import cx from "classnames"
import { StaticImage } from "gatsby-plugin-image"

import { Container } from "@ui/Container"
import { Row } from "@ui/Row"
import TrianglesFirst from "@icons/contacts/ContactsDesign3.svg"
import TrianglesSecond from "@icons/contacts/ContactsDesign4.svg"
import TrianglesThird from "@icons/contacts/ContactsDesign5.svg"

import * as s from "./Information.module.sass"
import WartaIcon from "@icons/Warta.svg"

type InformationProps = {
  address: string
  phoneNumber1: string
  phoneNumber2?: string
  email: string
  className?: string
}

export const Information: React.FC<InformationProps> = ({
  address,
  phoneNumber1,
  phoneNumber2,
  email,
  className,
}) => {
  return (
    <section className={cx(s.root, className)}>
      <div className={s.contentWrapper}>
        <Container>
          <Row className={s.row}>
            <div className={s.content}>
              <p>{address}</p>
              <div className={s.phones}>
                <a href={`tel:${phoneNumber1}`}>{phoneNumber1}</a>
                {phoneNumber2 && (
                  <a href={`tel:${phoneNumber2}`}>{phoneNumber2}</a>
                )}
              </div>
              <a className={s.email} href={`mailto:${email}`}>
                {email}
              </a>
            </div>
          </Row>
        </Container>
      </div>
      <div className={s.image}>
        <StaticImage
          src="../../../images/ContactsBackground.jpg"
          alt="Kosei"
          layout="fullWidth"
          style={{ height: "100%" }}
        />
      </div>
      <Container className={s.container}>
        <Row className={s.row}>
          <a
            href="https://www.google.com"
            target="_blank"
            className={s.logoLink}
          >
            <WartaIcon className={s.logo} />
          </a>
        </Row>
      </Container>
      <TrianglesFirst className={s.trianglesFirst} />
      <TrianglesSecond className={s.trianglesSecond} />
      <TrianglesThird className={s.trianglesThird} />
    </section>
  )
}

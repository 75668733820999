import React from "react"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

import { CustomPageProps } from "@utils/types"
import { BaseLayout } from "@layouts/BaseLayout"
import { Map } from "@components/contacts/Map"
import { Information } from "@components/contacts/Information"

const ContactsPage: React.FC<CustomPageProps> = ({
  data,
  pageContext,
  location,
}) => {
  // @ts-ignore
  const content = data.directus.items.contacts
  // @ts-ignore
  const address = content.translations[0].address
  // @ts-ignore
  const seo = data.directus.items.seo
  const seoContent = seo.translations[0]

  return (
    <BaseLayout
      pageContext={pageContext}
      location={location}
      noFooter
      title={seoContent.title_contacts || "Як з нами зв'язатися"}
      description={
        seoContent.description_contacts ||
        "Ви можете приїхати до нас в офіс за адресою: м Київ, бульвар Академіка Вернадського, 36-В. (Територія Будремкомплект НАН України)\n" +
          "Зателефонувати нам за номерами: +38 (099) 506-50-47, +38 (097) 496-82-63\n" +
          "Або написати нам на email: office@kosei.org.ua\n" +
          'Також можете залишити заявку на сайті перейшовши на сторінку "Контакти"'
      }
      image={getSrc(seo.contacts_image.imageFile)}
    >
      <Map />
      <Information
        address={address}
        phoneNumber1={content.phone_number_1}
        phoneNumber2={content.phone_number_2}
        email={content.email}
      />
    </BaseLayout>
  )
}

export const query = graphql`
  query ContactsPage {
    directus {
      items {
        contacts {
          email
          phone_number_1
          phone_number_2
          translations(filter: { languages_code: { code: { _eq: "uk-UA" } } }) {
            address
          }
        }
        seo {
          translations(filter: { languages_code: { code: { _eq: "uk-UA" } } }) {
            title_contacts
            description_contacts
          }
          contacts_image {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData(width: 1200, height: 627)
              }
            }
          }
        }
      }
    }
  }
`

export default ContactsPage
